import React from "react";
import Header from "../components/Header";
import backgroundImage from "../assets/images/landing-background.png";

const HomePage = () => {
  return (
    <div className="relative">
      <Header />

      <div
        className="relative z-0 h-[calc(100vh)]"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          width: "100%",
        }}
      >
        <div className="relative z-10 h-full flex items-center">
          <div className="w-full mx-6 sm:mx-12 md:mx-24">
            <h1 className="text-4xl sm:text-6xl md:text-[80px] max-w-3xl mb-6 sm:mb-12 leading-tight">
              Plan your perfect journey
            </h1>
            <p className="text-xl sm:text-2xl md:text-[40px] max-w-2xl mb-10 sm:mb-20 text-gray-400 font-medium">
              Coming Soon ... 👀
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
