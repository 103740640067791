import React from "react";
import logo from "../assets/images/logo.png";

const Header = () => {
  return (
    <header className="fixed top-0 left-0 right-0 z-30 bg-white border-b border-gray-200 h-24 sm:h-36">
      <div className="mx-4 sm:mx-12 h-full flex items-center">
        <div className="w-full h-16 sm:h-20 flex items-center justify-center">
          <div className="w-28 sm:w-36">
            <img src={logo} alt="Logo" className="h-7 sm:h-9 w-auto mx-auto" />
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
